<template>
  <v-row justify-md="center" align="stretch">
    <v-col md="4">
      <PasswordForgottenCard/>
    </v-col>
  </v-row>
</template>

<script>
import PasswordForgottenCard from '@/components/Authorisation/PasswordForgottenCard.vue';

export default {
  name: 'PasswordForgotten',
  components: {
    PasswordForgottenCard,
  },
  created() {
    this.$hero.$emit('setCanSearch', false);
    this.$hero.$emit('setTitle', 'Wachtwoord vergeten');
    this.$hero.$emit('setBreadcrumbs', []);
  },
};
</script>

<style scoped>

</style>
