<template>
  <v-card height="100%">
    <v-form @submit.prevent="handleRegister()" ref="form" v-model="valid">
      <v-card-title class="text-h6">Ik wil mijn wachtwoord aanvragen</v-card-title>
      <v-card-text>
        <v-alert
            :value="!!alertMessage.length"
            class="mb-10"
            transition="fade-transition"
            :type="alertType"
        >
          {{ alertMessage }}
        </v-alert>
        <VTextField
            :rules="[(v) => !!v || 'E-Mail is verplicht', () => serverError('email')]"
            label="E-Mail"
            class="email"
            v-model="form.email"
            tabindex="11"
        />
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn tabindex="12" type="submit" color="primary" :loading="isLoading">Wachtwoord aanvragen</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { passwordForgotten } from '@/api/endpoints/authorisation/password.js';
import BaseForm from '../BaseForm.vue';

export default {
  name: 'PasswordForgottenCard',
  extends: BaseForm,
  data() {
    return {
      alertType: 'success',
      alertMessage: '',
      isLoading: false,
      form: {
        email: '',
      },
    };
  },
  mounted() {
    this.form.email = this.$route.params.email || '';
  },
  methods: {
    handleRegister() {
      this.validate();

      if (!this.valid) return;

      this.isLoading = true;

      passwordForgotten(this.form.email)
          .then(() => {
            this.alertMessage = 'Er is een wachtwoord vergeten mail verstuurd mits er een account bestaat met het gegeven e-mail.';
            this.alertType = 'success';
          })
          .catch(() => {
            this.alertMessage = 'De ingevulde gegevens kloppen niet.';
            this.alertType = 'error';
          })
          .finally(() => {
            this.isLoading = false;
            this.validate();
          });
    },
  },
};
</script>
